<template>
    <div class="my-1 px-1 w-full md:w-1/3 lg:my-4 lg:px-4 lg:w-1/23 inline-block align-top">
        <article class="overflow-hidden rounded-lg shadow-lg cursor-pointer">
            <a v-on:click="showCity()">
                <img class="block h-auto w-full" :src="src">
            </a>
            <header class="leading-tight p-2 md:p-4">
                <h1 class="sm:text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-2xl">
                    <a class="no-underline text-white uppercase">
                        {{ name }}
                    </a>
                </h1>
                <div class="w-12 h-1 bg-red_n ml-auto mr-auto mt-2" v-if="getSelect()"></div>
            </header>
        </article>
    </div>
</template>

<script>
export default {
    props: {
        id: String,
        name: String,
        src: String,
    },
    data: function () {
        return {
            citys: [
                {
                    name: "BRUXELLES",
                    adress: "Office 503, 5th Floor, South Center Titanium Marcel \nBroodthaer square 8 Brussels 1060, Belgium",
                    src: "url(/uploads/villes/brussels.jpg)",
                    logo: "/images/logos/arpon_m.svg",
                    id: 4
                },
                {
                    name: "Paris",
                    adress: "Immeuble SOPARQ - Bat C BIS 11 - 19 rue de la Vanne\n92120 MONTROUGE",
                    src: "url(/uploads/villes/paris.jpg)",
                    logo: "/images/logos/arpon_p.svg",
                    id: 1
                },
                {
                    name: "Nantes",
                    adress: "Europarc de la Chantrerie 5 rue Edouard Nignon\n44300 NANTES",
                    src: "url(/uploads/villes/nantes.jpg)",
                    logo: "/images/logos/arpon_o.svg",
                    id: 2
                },
                {
                    name: "Lyon",
                    adress: "6 boulevard des Monts d'Or 69580\nSATHONAY CAMP",
                    src: "url(/uploads/villes/lyon.jpg)",
                    logo: "/images/logos/arpon_s.svg",
                    id: 3
                },
                {
                    name: "Marseille",
                    adress: "Wellio Marseille - La Joliette 48 Quai du Lazaret 13002 Marseille",
                    src: "url(/uploads/villes/marseille.jpg)",
                    logo: "/images/logos/arpon_s.svg",
                    id: 5
                },
                {
                    name: "Lille",
                    adress: "Lille Europe, Immeuble Le Leeds, 253 Boulevard Leeds, 59777 Lille, France",
                    src: "url(/uploads/villes/lille.jpg)",
                    logo: "/images/logos/arpon_s.svg",
                    id: 6
                },
                {
                    name: "Clermont-Ferrand",
                    adress: "53 rue Bonnabaud, 63000 Clermont-Ferrand",
                    src: "url(/uploads/villes/clermont.jpg)",
                    logo: "/images/logos/arpon_s.svg",
                    id: 7
                },
            ],
            interval: false,
            opacity: 0,
        }
    },
    methods: {
        getCityById(id) {
            for (var i = 0; i < this.citys.length; i++) {
                if (this.citys[i].id == id)
                    return this.citys[i];
            }
            return null;
        },
        showCity() {
            this.setCityById(this.getCityById(this.id));
        },
        setCityById(city) {

            if (city == null)
                return;
            this.$root.index = city.id;

            this.$root.city = city.name;
            this.$root.adress = city.adress;
            this.$root.$refs.city_back.style.backgroundImage = city.src;
        },
        getSelect() {
            if (this.$root.index == this.id)
                return true;
            return false;
        },
        getSelectImage() {
            if (this.$root.index == this.id)
                return this.citys[this.id].logo;
            return "";
        },
        fadeOut(el, duration, inc, start) {

            clearInterval(this.interval);
            this.opacity = start;

            this.interval = window.setInterval(function () {
                this.opacity += inc;
                if (this.opacity >= 1) {
                    clearInterval(this.interval);
                    return;
                }
                el.style.opacity = this.opacity;
            }.bind(this), duration);
        }
    }
}
</script>
