require('./bootstrap');
import { data } from 'jquery';
require('intersection-observer');
import 'babel-polyfill';
import Vue from 'vue';

import VueObserveVisibility from 'vue-observe-visibility'


Vue.config.devtools = true;

Vue.component('city-info', require('./components/CityComponent.vue').default);
Vue.component('company-info', require('./components/CompanyComponent.vue').default);

Vue.use(VueObserveVisibility)

Vue.component('info-comp', require('./components/InfoComponent.vue').default);

const app = new Vue({
    el: '#app',
    data: {
        city: "Paris",
        adress: "Immeuble SOPARQ - Bat C BIS 11 - 19 rue de la Vanne\n92120 MONTROUGE",
        companys: [],
        index: 0,
        loaded: false,
        info: true,
    },
    created() {

    },
    methods: {

        closeInfoBar() {
            this.info = false;
        },

        scrollToElement() {
            $('html, body').animate({
                scrollTop: $(".scroll_p").offset().top - 80
            }, 1500);
        },
    }
});