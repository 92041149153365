<template>
     <div class="slide">
        <img class="p-4" v-bind:src="'/uploads/companys/'+src" height="100" width="200" alt="" />
    </div>
</template>

<script>
    export default {
        props: {
            src: String,
            name: String,
        },
        methods: {
            
        }
    }
</script>
