<template>
    <div class="relative inline-block  mb-4 w-100 h-40 text-center text-bold" v-observe-visibility="visibilityChanged" >
        <label class="text-white text-7xl ">{{displayNumber}} {{extra}}</label><br>
        <label class="text-2xl text-white">{{title}}</label>
    </div>
</template>

<script>
    export default {
        props:{
            'number':{default:0}, 
            'title':{default:""},
            'extra':{default:""}
        },
        data: function(){
            return {
                displayNumber:0,
                interval:false
            }
        },
        
        methods:{
            animateNumber () {
                
                clearInterval(this.interval);
                if (this.number == this.displayNumber)
                    return;

                this.interval = window.setInterval(function(){
                    if (this.displayNumber != this.number) {
                        var change = (this.number - this.displayNumber) / 10;
                        change = change >= 0 ? Math.ceil(change) : Math.floor(change);
                        this.displayNumber = this.displayNumber + change;
                    }
                }.bind(this), 30);
            },
            visibilityChanged (isVisible, entry) {
                if (entry.isIntersecting)
                    this.animateNumber();
            }
        }
    }
</script>
